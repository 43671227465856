@import "src/theme";

.section {
    min-height: inherit;
    background-image: url("~photos/background.jpg");
    background-repeat: no-repeat;
    // background-size: cover;
    font-family: $custom-font-3;
    background-size: 100% 100%;
    height:100%;

    // to center the texts
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1000px) {
        min-height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 2px solid black;
    }
}

.details {

    // font-family: $custom-font-3;
    .announcement {
        font-family: $primary-cursive-font-family;
        font-weight: 100;
    }

    .date {
        padding-top: 40px;
        // padding-bottom: 40px;
        font-family: $custom-font-3;
        // font-size: 3.1em;
        font-weight: 100;


        @media (min-width: $size-huge) {
            font-size: 3.5em;
        }

        // Desktops
        @media (min-width: $size-extra-large) and (max-width: $size-huge - 1) {

            font-size: 3.5em;

        }

        // Laptops / Desktops
        @media (min-width: $size-large) and (max-width: $size-extra-large - 1) {

            font-size: 3em;

        }

        // Tablets (Portrait)
        @media (min-width: $size-medium) and (max-width: $size-large - 1) {

            font-size: 3.4em;

        }

        // Tablets (Landscape)
        @media (min-width: $size-medium) and (max-width: $size-large - 1) and (orientation: landscape) {

            font-size: 3em;

        }

        // Mobile (Landscape)
        @media (min-width: $size-small) and (max-width: $size-medium - 1) {

            font-size: 2.6em;

        }

        // Mobile
        @media (min-width: $size-extra-small) and (max-width: $size-small - 1) {
            font-size: 1.5em !important;
        }
    }

    .location {
        // font-family: $custom-standard-font;
        font-weight: 100;

        a:link,
        a:visited,
        a:hover,
        a:link {
            color: black;
        }

        padding-top: 40px;
        padding-bottom: 40px;
        font-family: $custom-standard-font;
        font-size: 3.1em;
        font-weight: 100;
    }
}

.rsvpButton {
    font-family: $custom-font-3;
    font-weight: 100;
    font-size: 1.1em;
    // padding-top: 40px;
    // padding-bottom: 40px;
    // background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    // &:hover {
    //     background-color: $secondary-color;
    // }
    button {
        @media (min-width: $size-huge) {
            font-size: 2em;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        // Desktops
        @media (min-width: $size-extra-large) and (max-width: $size-huge - 1) {
            font-size: 2em;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        // Laptops / Desktops
        @media (min-width: $size-large) and (max-width: $size-extra-large - 1) {
            font-size: 2em;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.pleserespond {

    @media (min-width: $size-huge) {
        font-size: 1.5em;
    }

    // Desktops
    @media (min-width: $size-extra-large) and (max-width: $size-huge - 1) {
        font-size: 1.5em;

    }

    // Laptops / Desktops
    @media (min-width: $size-large) and (max-width: $size-extra-large - 1) {
        font-size: 1.5em;
    }

}