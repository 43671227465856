@import "src/theme";

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.item {

    h1,
    h2,
    h3 {
        // font-family: $primary-cursive-font-family;
        font-family: $custom-font-family;
        font-weight: 600;

        a:link,
        a:visited,
        a:hover,
        a:link {
            color: black;
        }
    }

    img {
        width: 50%;
    }

    h4 {
        font-size: 30px;
        // font-family: $primary-font-family;
        font-family: $custom-font-family;
    }

    // Mobile
    @media (min-width: $size-extra-small) and (max-width: $size-small - 1) {

        .leftmaintitle,
        .leftfirsttitle {
            font-size: 2em !important;
        }
    }



    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 5px 55px 35px;
    }

    .item-col {
        padding-top: 70px;
        padding-bottom: 70px;

        @include media-breakpoint-down(sm) {
            padding: 0;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: $border-color;
        }

        &.left {
            border-right-style: solid;
            border-right-width: 2px;
            border-right-color: $border-color;
            text-align: center;
            padding-right: 25px;

            @include media-breakpoint-down(sm) {
                border-style: none;
                text-align: center;
                padding: 0;
            }
        }

        &.right {
            padding-left: 25px;
            font-family: "Handlee", cursive;

            @include media-breakpoint-down(sm) {
                text-align: center;
                padding: 0 0 0px;
            }

            .contact {
                margin-bottom: 20px;
                // font-family: $primary-font-family;
                // font-family: $custom-font-family;
                font-family: "Handlee", cursive;

                p {
                    margin-bottom: 0;
                }
            }

            .details {
                // font-family: $primary-font-family;
                font-size: 20px;
                // font-family: $custom-font-family;
                font-family: "Handlee", cursive;
            }

            .button {
                width: 100px;

                &:global(.btn-info),
                &:global(.btn-info:visited) {
                    background-color: lighten($primary-color, 20%);
                    border-color: $primary-color;
                }

                &:global(.btn-info:hover),
                &:global(.btn-info:active) {
                    background-color: $primary-color;
                }
            }
            // .rightMainTitle{
            //     @media (min-width: $size-huge) {
            //         // height: 1250px;
            //         // padding-top: 940px !important;
            //         // h1,h2,h3,h4,p{
            //         //     font-size: 1em;
            //         // }
            //         font-size: 40px !important;
                    
            //     }
            // }
            // Huge Desktops
            @media (min-width: $size-huge) {
                // height: 1250px;
                // padding-top: 940px !important;
                // h1,h2,h3,h4,p{
                //     font-size: 1em;
                // }
                .rightMainTitle{
                    font-size: 3em;
                }
                .details{
                    font-size: 2em;
                }
                // font-size: 3em;
                
            }

            // Desktops
            @media (min-width: $size-extra-large) and (max-width: $size-huge - 1) {
                // height: 960px;
                // padding-top: 750px !important;



                .rightMainTitle{
                    font-size: 2.5em;
                }
                .details{
                    font-size: 1.5em;
                }

            }

            // Laptops / Desktops
            @media (min-width: $size-large) and (max-width: $size-extra-large - 1) {
                font-size: 1.5em;
            }
        }
    }
}