@import "src/theme";

.navigation {
    // background-color: $primary-color;
    // background-color: #E0F7FA;
    // background: linear-gradient(62deg, #89CFF0 0%, #2ed8f2 50%, #B2EBF2 100%);
    background: linear-gradient(62deg, #B3E0F2 0%, #E0F7FD 50%, #FFFFFF 100%);

    // color: black;
    font-family: "Pacifico", cursive;
    .brand {
        // font-family: $primary-cursive-font-family;
        font-weight: 900;
        color: #003153;
        font-family: "Pacifico", cursive;
        // font-weight: 400;
        // font-style: normal;
    }

    :global(.dropdown-menu) {
        min-width: unset;

        .email {
            cursor: unset;
            border-bottom: lighten($primary-color, 50%);
            border-bottom-style: solid;
            border-bottom-width: 1px;
            font-size: 11px;
            padding-bottom: 8px;

            &:hover {
                background-color: unset;
            }
        }
    }
    .navlink{
        color: rgba(0, 49, 83, .8) !important;
        cursor: pointer
    }
}
.toggle{
    background-color: rgba(179, 224, 242, .8);
}
