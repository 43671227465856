@import "src/theme";

.header {
    height: 300px;
    background-image: url("~photos/header_small.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-color: $secondary-color !important;

    padding-top: 165px !important;
    padding-bottom: 0 !important;

    h1 {
        font-family: $primary-cursive-font-family;
        color: #ffffff;
        font-weight: 900;
        font-size: 2em;
    }

    // Huge Desktops
    @media (min-width: $size-huge) {
        height: 1250px;
        background-image: url("~photos/header_large.jpg");
        padding-top: 940px !important;

        h1 {
            font-size: 7.5em;
        }
    }

    // Desktops
    @media (min-width: $size-extra-large) and (max-width: $size-huge - 1) {
        height: 960px;
        background-image: url("~photos/header_large.jpg");
        padding-top: 750px !important;

        h1 {
            font-size: 6.5em;
        }
    }

    // Laptops / Desktops
    @media (min-width: $size-large) and (max-width: $size-extra-large - 1) {
        height: 900px;
        background-image: url("~photos/header_medium.jpg");
        padding-top: 600px !important;

        h1 {
            font-size: 5.5em;
        }
    }

    // Tablets (Portrait)
    @media (min-width: $size-medium) and (max-width: $size-large - 1) {
        height: 800px;
        background-image: url("~photos/header_medium.jpg");
        padding-top: 550px !important;

        h1 {
            font-size: 4.5em;
        }
    }

    // Tablets (Landscape)
    @media (min-width: $size-medium) and (max-width: $size-large - 1) and (orientation: landscape) {
        height: 760px;
        background-image: url("~photos/header_medium.jpg");
        padding-top: 500px !important;

        h1 {
            font-size: 4em;
        }
    }

    // Mobile (Landscape)
    @media (min-width: $size-small) and (max-width: $size-medium - 1) {
        height: 620px;
        background-image: url("~photos/header_medium.jpg");
        padding-top: 400px !important;

        h1 {
            font-size: 3.5em;
        }
    }

    // Mobile
    @media (min-width: $size-extra-small) and (max-width: $size-small - 1) {
        height: 550px;
        padding-top: 345px !important;

        h1 {
            font-size: 3em;
        }
    }
}

.caption {
    color: #ffffff; /* white text */
    padding: 10px; 
    font-family: $custom-font-3;
    right: 0;
    left: 0;
    // background: rgba(211, 211, 211, 0.3);
    // border: 2px solid #ffffff;
    text-shadow: 2px 2px 4px #000000; /* Horiozontal shadow, Vertical shadow, Blur distance, color of shadow */

    // Huge Desktops
    @media (min-width: $size-huge) {

        font-size: 4em;

    }

    // Desktops
    @media (min-width: $size-extra-large) and (max-width: $size-huge - 1) {

        font-size: 3.5em;

    }

    // Laptops / Desktops
    @media (min-width: $size-large) and (max-width: $size-extra-large - 1) {

        font-size: 3.5em;

    }

    // Tablets (Portrait)
    @media (min-width: $size-medium) and (max-width: $size-large - 1) {

        font-size: 2.5em;

    }

    // Tablets (Landscape)
    @media (min-width: $size-medium) and (max-width: $size-large - 1) and (orientation: landscape) {


        font-size: 2.3em;

    }

    // Mobile (Landscape)
    @media (min-width: $size-small) and (max-width: $size-medium - 1) {

        font-size: 2.3em;

    }

    // Mobile
    @media (min-width: $size-extra-small) and (max-width: $size-small - 1) {

            font-size: 1.5em;
        
    }
}

.carousel .item img {
    max-height: 300px;
    min-width: auto;
}

.carousel_caption {
    bottom: 0;
    padding-bottom: 0;
}
@keyframes flashColor {
    0% {color: red; opacity: 1;}
    50% {color: blue; opacity: 0;}
    100% {color: red; opacity: 1;}
  }

.flashing-text {
    animation: flash 1s linear infinite;
}