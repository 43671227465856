@import "src/theme";
.intro {
    text-align: center;
}

.form {
    width: 100%;
    max-width: 1600px;
    padding: 30px;
    margin: 0 auto;
    font-family: "Handlee", cursive;
    font-size: 20px;
    overflow-x: hidden;
}
