.container {
    text-align: center;
}

.main_container{
    
        // background-image: url("~photos/background.jpg");
        // background-repeat: repeat;
        // background-size: cover;
    
}