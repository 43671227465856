.form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;

    .links {
        margin-top: 20px;
        p {
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
