.p-fileupload-file-badge{
    display: none !important
}


.modal {
    display: grid;
    place-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
    transition: 0.6s;
    will-change: opacity visibility;
  }
  
  .modal-open {
    opacity: 1;
    visibility: visible;
  }
  
  .loader {
    width: 2.5rem;
    height: 2.5rem;
    border: 7px solid rgb(179, 224, 242);
    border-bottom-color: rgb(255,255,255);
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  